<template>
  <div class="row">
    <div class="col-md-12 col-lg-12">
      <c-tab
        :dense="true"
        :tabItems.sync="tabItems"
        :inlineLabel="true"
        height="830px"
        v-model="tab"
      >
        <template v-slot:default="tab">
          <component
            :eduCourseId.sync="popupParam.eduCourseId"
            @changeStatus="changeStatus"
            :is="tab.component"
          />
        </template>
      </c-tab>
    </div>
  </div>
</template>
<script>
export default {
  name: 'education-curriculumn-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        eduCourseId: '',
      }),
    },
  },
  data() {
    return {
      tab: 'educationCurriculumInfo',
      tabItems: [],
      addTabItems: []
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.tabItems = [
        { name: 'educationCurriculumInfo', icon: 'info', label: '교육과정 기본정보', class:"tab-orange", component: () => import(`${'./educationCurriculumInfo.vue'}`) }
      ]
      this.addTabItems = [
        // { name: 'educationTargetUser', icon: 'face', label: '교육 대상자', component: () => import(`${'./educationTargetUser.vue'}`) },
        { name: 'educationMaterial', icon: 'file_copy', label: '교육교재', component: () => import(`${'./educationMaterial.vue'}`) },
        { name: 'educationHistory', icon: 'history', label: '교육이력', component: () => import(`${'./educationHistory.vue'}`) },
      ]
      if (this.popupParam.eduCourseId) {
        this.tabItems = this.tabItems.concat(this.addTabItems);
      }
    },
    changeStatus() {
      this.tabItems = this.tabItems.concat(this.addTabItems);
    },
  }
};
</script>
